<template>
    <div class="container">
      <h2>Change Password</h2>
      
      <div class="field">
        <label class="label">Old Password</label>
        <div class="control">
          <input class="input" type="password" v-model="oldPassword" placeholder="Old Password">
        </div>
      </div>
  
      <div class="field">
        <label class="label">New Password</label>
        <div class="control">
          <input class="input" type="password" v-model="newPassword" placeholder="New Password">
        </div>
      </div>
  
      <div class="field">
        <label class="label">Confirm New Password</label>
        <div class="control">
          <input class="input" type="password" v-model="confirmNewPassword" placeholder="Confirm New Password">
        </div>
      </div>
  
      <div class="field">
        <div class="control">
          <button class="button is-primary" @click="changePassword">Change Password</button>
        </div>
      </div>
  
      <div v-if="message" class="notification" :class="{'is-success': isSuccess, 'is-danger': !isSuccess}">
        {{ message }}
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { API_URL } from '../constants';
  
  export default {
    data() {
      return {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        message: '',
        isSuccess: false
      };
    },
  
      methods: {
        async changePassword() {
            if(this.newPassword !== this.confirmNewPassword) {
                this.message = "New passwords do not match.";
                this.isSuccess = false;
                return;
            }

            try {
                const response = await axios.post(API_URL+'/api/v1/change-password/', {
                old_password: this.oldPassword,
                new_password: this.newPassword
                }, {
                headers: { 'Authorization': `Token ${localStorage.getItem('userToken')}` }
                });

                this.message = "Password successfully changed.";
                this.isSuccess = true;
                
                // Redirect to the home page after a successful password change
                this.$router.push({ name: 'home' });
                
            } catch (error) {
                this.message = "An error occurred while changing the password.";
                this.isSuccess = false;
                console.error(error);
            }
        }
    }
  }
  </script>