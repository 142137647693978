<template>
  <div class="container">
    <!-- Display registration success message -->
    <div v-if="registrationMessage" class="notification is-success">
      {{ registrationMessage }}
    </div>

    <!-- Display registration error message -->
    <div v-if="registrationError" class="notification is-danger">
      {{ registrationError }}
    </div>

    <!-- Add Region Field -->
    <div class="field">
      <label class="label">Pays</label>
      <div class="control">
        <input class="input" type="text" placeholder="Pays" v-model="region">
      </div>
    </div>

    <div class="field">
      <label class="label">Nom complet</label>
      <div class="control">
        <input class="input" type="text" placeholder="Nom complet" v-model="fullName" required>
      </div>
    </div>

        <!-- Add Customer Group Dropdown -->
    <div class="field">
      <label class="label">Type d’entreprise</label>
      <div class="control">
        <div class="select">
          <select v-model="customerGroup">
            <option value="">Sélectionnez</option>
            <option value="Producteur">Producteur</option>
            <option value="Distributeur">Distributeur</option>
            <option value="Employés Syngenta">Employés Syngenta</option>
            <option value="Etudiants">Etudiants</option>
            <option value="Professeur/Chercheur">Professeur/Chercheur</option>
            <option value="Société agricole">Société agricole</option>
            <option value="Autre">Autre</option>
          </select>
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <input class="input" type="email" placeholder="Email" v-model="email" required>
      </div>
    </div>

    <div class="field">
      <label class="label">Mot de passe</label>
      <div class="control">
        <input class="input" type="password" placeholder="Mot de passe" v-model="password" required>
      </div>
    </div>

    <div class="field">
      <label class="label">Confirmez votre mot de passe</label>
      <div class="control">
        <input class="input" type="password" placeholder="Confirmez votre mot de passe" v-model="passwordConfirmation" required>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <button class="button is-link" @click="register">S’inscrire</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '../constants'; // Ensure this is set up properly

export default {
  data() {
    return {
      region: '',
      customerGroup: '',
      fullName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      registrationMessage: '',
      registrationError: ''
    };
  },
  methods: {
    async register() {
      if (this.password !== this.passwordConfirmation) {
        this.registrationError = "Passwords do not match.";
        return;
      }
      try {
        const response = await axios.post(`${API_URL}/api/v1/register/`, {
          full_name: this.fullName,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          region: this.region,
          customer_group: this.customerGroup,   // Ensure this key matches what the backend expects
        });
        // Inform the user that their account awaits admin approval
        this.registrationMessage = "Registration successful. Please check your email to verify your account.";
        this.registrationError = ''; // Clear any previous error
        // Clear form fields after successful registration
        this.fullName = '';
        this.email = '';
        this.password = '';
        this.passwordConfirmation = '';
        this.region = ''; 
        this.customerGroup = '';
      } catch (error) {
        // Handle error
        this.registrationMessage = ''; // Clear any previous message
        if (error.response && error.response.data && typeof error.response.data === 'object') {
          this.registrationError = Object.values(error.response.data).join(' ');
        } else {
          this.registrationError = "An error occurred during registration. Please try again.";
        }
      }
    }
  }
}
</script>