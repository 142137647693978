<template>
    <div class="container">
        <!-- Updated Header with Logo and Title on the Same Row -->
        <div class="header-row">
            <h1 class="title newColor has-text-weight-bold">
                Compatibilité des Produits Syngenta
            </h1>
            <div class="header-with-logo">
                <img src="@/assets/mixsyn-logo2.svg" alt="MixSyn Logo" class="header-logo"/>
            </div>
        </div>
        <!-- Add a dropdown menu for selecting crops -->
        <p><br></p>
        <div class="field">
            <label class="label">Pays:</label>
            <div class="control">
                <div class="select" :class="{ 'is-danger': showErrors && !selected_country }">
                    <select v-model="selected_country" class="custom-width" required>
                        <option v-for="ctry in country" :value="ctry.name">{{ ctry.name }}</option>
                    </select>
                </div>
                <p v-if="showErrors && !selected_country" class="help is-danger">This field is required</p>
            </div>
        </div>
        <br>
         <!-- Add a dropdown menu for selecting crops -->
        <div class="field">
            <label class="label">Cultures:</label>
            <div class="control">
                <div class="select" :class="{ 'is-danger': showErrors && !selected_crop }">
                    <select v-model="selected_crop" class="custom-width" required>
                        <option v-for="crop in crops" :value="crop.name">{{ crop.name }}</option>
                    </select>
                </div>
                <p v-if="showErrors && !selected_crop" class="help is-danger">This field is required</p>
            </div>
        </div>
        <br>
        <div class="field">
            <label class="label">Produit 1:</label>
            <div class="control">
                <input v-model="product_a" @input="searchProduct1" class="input" :class="{ 'is-danger': showErrors && !product_a }" placeholder="Produit 1">
            </div>
            <p v-if="showErrors && !selected_crop" class="help is-danger">This field is required</p>
        </div>
        <div class="dropdown" v-bind:class="{ 'is-active': showSuggestions1 }">
            <div class="dropdown-menu">
                <div class="dropdown-content">
                    <a v-for="suggestion in suggestions1" :key="suggestion.id" @click="selectSuggestion1(suggestion)" class="dropdown-item">{{ suggestion.name }}</a>
                </div>
            </div>
        </div>
        <div class="field">
            <label class="label">Produit 2:</label>
            <div class="control">
                <input v-model="product_b" @input="searchProduct2" class="input" :class="{ 'is-danger': showErrors && !product_b }" placeholder="Produit 2">
            </div>
            <p v-if="showErrors && !selected_crop" class="help is-danger">This field is required</p>
        </div>
        <div class="dropdown" v-bind:class="{ 'is-active': showSuggestions2 }">
            <div class="dropdown-menu">
                <div class="dropdown-content">
                    <a v-for="suggestion in suggestions2" :key="suggestion.id" @click="selectSuggestion2(suggestion)" class="dropdown-item">{{ suggestion.name }}</a>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <button @click="checkCompatibility" class="button is-primary">Vérifier la compatibilité</button>
            </div>
        </div>

        <div v-if="result && is_compatible" class="notification is-success works">
            {{ result }}
        </div>
        <div v-if="!is_compatible && result" class="notification is-danger">
            {{ result }}
        </div>
        <div v-if="order" class="notification is-info">
        <strong>Ordre d’introduction:</strong> {{ order }} doit être introduit en premier dans la cuve de mélange.
    </div>
    <div v-if="result && is_compatible" class="notification is-light content">
        <h3 class="has-text-weight-semibold">NOTE IMPORTANTE :</h3>
        <p>Les informations disponibles sont issus des tests de compatibilité physique conduits dans des conditions spécifiques par nos équipes techniques.</p>
        <p>Avant toute application d'un mélange, veillez à vérifier attentivement les étiquettes de vos produits et qu'il n'y a pas d'incompatibilité biologique. Pour plus d'informations, veuillez contacter votre représentant local de Syngenta®.</p>
        <p>Syngenta vous conseille la procédure suivante pour optimiser vos mélanges …</p>
        
        <h4 class="has-text-weight-semibold">ORDRE D’INTRODUCTION DES PRODUITS PHYTOSANITAIRES DANS UNE CUVE SOUS AGITATION REMPLIE AU 2/3 D’EAU</h4>
        
        <h5 class="has-text-weight-semibold">PRODUITS PARTICULIERS</h5>
        <ol>
            <li>Les faibles doses inférieures à 100 g de granulés (WG)</li>
            <li>Les sachets hydrosolubles (WSB)</li>
        </ol>
        
        <h5 class="has-text-weight-semibold">PRODUITS SOLIDES</h5>
        <ol>
            <li>Les autres granulés dispersables (WG)</li>
            <li>Les poudres (WP)</li>
        </ol>
        
        <h5 class="has-text-weight-semibold">PRODUITS LIQUIDES</h5>
        <ol>
            <li>Les adjuvants de compatibilité (ex. : blocage des ions calcium si usage de glyphosate)</li>
            <li>Les suspensions concentrées (SC)</li>
            <li>Les suspensions de capsules (CS)</li>
            <li>Les suspo-émulsions (SE)</li>
            <li>Les dispersions huileuses (OD)</li>
            <li>Les concentrés dispersables (DC)</li>
            <li>Les concentrés émulsionnables (EC)</li>
            <li>Les liquides solubles (SL)</li>
            <li>Les autres adjuvants (huile, mouillant…)</li>
        </ol>
        
        <h5 class="has-text-weight-semibold">AUTRES</h5>
        <ol>
            <li>Les correcteurs de carences contenant Mg, Mn, Cu</li>
            <li>Les engrais</li>
        </ol>

        <p>IMPORTANT avant tout mélange : Lire les étiquettes des produits commerciaux et Respecter les bonnes pratiques agricoles.</p>
    </div>

        <!-- Disclaimer Section -->
        <div class="notification is-light">
        <p>© 2024 Syngenta. Important : Veuillez toujours lire et suivre les instructions figurant sur l'étiquette. Certains produits peuvent ne pas être homologués pour la vente ou l'utilisation dans votre pays. Veuillez vérifier auprès de votre conseiller Syngenta en cas de doute.</p>
        <p>Aucun test n'a été effectué sur la sécurité des cultures ou les performances des produits ; par conséquent, l'utilisation se fait aux risques de l'utilisateur. Syngenta ne fournit aucune garantie concernant la tolérance des cultures ou la compatibilité des mélanges.</p>
    </div>
    </div>

    </template>

<script>
    import axios from "axios";
    import { API_URL } from '../constants';
    
    export default {
        name: 'CompatibilityCheck',
        data() {
        return {
            csrf_token: null,
            showErrors : false,
            selected_crop: "",
            selected_country: "",
            crops: [],
            country: [],
            product_a: "",
            suggestions1: [],
            showSuggestions1: false,
            product_b: "",
            suggestions2: [],
            showSuggestions2: false,
            result: "",
            comment: "",
            order: "",
            feedbackMessage: '',
            isSuccess: false,   
        };
        },
        async mounted() {
            // Fetch the data for the crops from the REST API
            try {
                const cropsResponse = await axios.get(API_URL + '/api/v1/crops/', {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('userToken')}`  // Include the auth token
                }
                });
                this.crops = cropsResponse.data.crops;

                const countryResponse = await axios.get(API_URL + '/api/v1/country/', {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('userToken')}`  // Include the auth token
                }
                });
                this.country = countryResponse.data.country;
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally, update the feedback message for the user
                this.feedbackMessage = "Failed to load data.";
                this.isSuccess = false;
            }
            },
        methods: {
            async searchProduct1() {
                if (this.product_a.length < 1) {
                    this.showSuggestions1 = false;
                    return;
                }
                try {
                    const response = await axios.get(API_URL+'/api/v1/products-list/', {
                    params: {
                        query: this.product_a,
                    },
                    });
                    this.suggestions1 = response.data;
                    this.showSuggestions1 = true;
                } catch (error) {
                    console.error(error);
                }},
            async searchProduct2() {
                if (this.product_b.length < 1) {
                    this.showSuggestions2 = false;
                    return;
                }
                try {
                    const response = await axios.get(API_URL+'/api/v1/products-list/', {
                    params: {
                        query: this.product_b,
                    },
                    });
                    this.suggestions2 = response.data;
                    this.showSuggestions2 = true;
                    
                } catch (error) {
                    console.error(error);
                }
                },

            selectSuggestion1(suggestion) {
                this.product_a = suggestion.name;
                this.showSuggestions1 = false;
            },
            selectSuggestion2(suggestion) {
                this.product_b = suggestion.name;
                this.showSuggestions2 = false;
            },

        
            async checkCompatibility() {
                this.showErrors = true;
                try {
                    const response = await axios.post(API_URL + '/api/v1/compatibility-check/', {
                    product_a: this.product_a,
                    product_b: this.product_b,
                    crop: this.selected_crop,
                    country: this.selected_country,
                    }, {
                    headers: {
                        'X-CSRFToken': this.csrf_token,
                        'Authorization': `Token ${localStorage.getItem('userToken')}`  // Include the auth token
                    }
                    });

                    // Handle success response
                    this.feedbackMessage = "Compatibility check successful";
                    this.isSuccess = true;
                    // Process the response data
                    this.result = response.data.message;
                    this.comment = response.data.comment;
                    this.is_compatible = response.data.is_compatible;
                    this.order = response.data.order || "";
                } catch (error) {
                    // Handle errors
                    this.feedbackMessage = error.response.data.message || "An error occurred during compatibility check.";
                    this.isSuccess = false;
                }
                }
            }
        }
</script>

<style scoped>
  .custom-width {
    width: 300px;
  }

  .notification.is-info {
    background-color: #01014b;
  }

  .notification.is-light{
    background-color: #e6e6e6;
  }

  .button.is-primary {
    background-color: #01014b;
  }


  .disclaimer {
    margin-top: 20px;
    font-size: 0.9em;
    color: #666;
  }

  .header-row {
    display: grid;
    grid-template-columns: 1fr auto; /* Title takes remaining space, logo takes auto space */
    grid-template-rows: auto auto; /* Two rows */
    grid-template-areas: 
      "empty logo"
      "title title"; /* Defines the areas, 'empty' is an empty cell */
    align-items: start; /* Aligns items to the start of the grid area */
}

.title.newColor {
    grid-area: title; /* Assigns the title to the 'title' area */
    margin: 0; /* Removes default margin */
    align-self: start; /* Aligns the title to the top of its area */
    color: #01014b;
}

.header-with-logo {
    grid-area: logo; /* Assigns the logo to the 'logo' area */
    justify-self: end; /* Aligns the logo to the end (right) of its area */
}

.header-logo {
    height: 100px; /* Adjust to your preference */
    width: auto; /* Maintains the aspect ratio */
}
</style>
