<template>
  <div float rid="wrapper">
  <div class="translate-container">
          <!-- Google Translate Widget -->
          <div id="google_translate_element"></div>
        </div>
    <nav class="navbar is-dark">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item"><img src="@/assets/mixsyn-logo.svg" /></router-link>

        <a class="navbar-burger" arial="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" id="navbar-menu" v-bind:class="{'is-active': showMobileMenu }">
        <div class="navbar-end">
          <router-link to="/" class="navbar-item">Accueil</router-link>
          <!-- <router-link to="/about" class="navbar-item">About</router-link> -->
          
          <!-- Logout Button -->
          <a v-if="isAuthenticated" class="navbar-item" @click="logout">Se déconnecter</a>
        </div>
      </div>
    </nav>


    <section class="section">
      <router-view/>
    </section>

    <footer class="footer">
      <p class="has-text-centered">
      <img src="@/assets/syn_logo.webp" width="200" alt="Second Logo" class="footer-logo"/><br />(c) {{ currentYear }}</p>
    </footer>

  </div>
</template>

<script>
import { EventBus } from './eventBus';
export default {
  data() {
    return {
      showMobileMenu:false,
      loggedIn: !!localStorage.getItem('userToken'), // Initial check
    }
  },
  mounted() {
    EventBus.on('auth-change', (status) => {
      this.loggedIn = status;
    });
    this.loadGoogleTranslate();
  },
  beforeUnmount() {
    EventBus.off('auth-change');
  },
  computed: {
    isAuthenticated() {
      return this.loggedIn;
    },
    currentYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('userToken'); // Clear the token
      EventBus.emit('auth-change', false); // Emit logout event
      this.$router.push({ name: 'login' }); // Redirect to the login page
    },
    loadGoogleTranslate() {
      window.googleTranslateElementInit = this.googleTranslateElementInit;
      var translateScript = document.createElement('script');
      translateScript.type = 'text/javascript';
      translateScript.async = true;
      translateScript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      document.body.appendChild(translateScript);
    },
    googleTranslateElementInit() {
      new google.translate.TranslateElement({pageLanguage: 'fr'}, 'google_translate_element');
    }
  }
}
</script>

<style lang="scss">
@import '../node_modules/bulma';

.translate-container {
  position: fixed;
  top: 50px; /* Adjust this value to move it up or down */
  right: 0px; /* Adjust distance from the right edge */
  z-index: 1000; /* Ensure it stays on top of other content */
}
</style>
