<template>
<div class="register">
    <ChangePassword />
</div>
</template>

<script>
import ChangePassword from '@/components/ChangePassword.vue';


export default {
name: 'ChangePasswordView',
components: {
    ChangePassword
}
}
</script>