import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FileUploadView from '../views/FileUploadView.vue'
import LoginView from '../views/LoginView.vue'          // Import the Login component
import RegisterView from '../views/RegisterView.vue'    // Import the Register 
import ChangePasswordView from '../views/ChangePasswordView.vue'; // Import ChangePassword component

function isAuthenticated() {
  return !!localStorage.getItem('userToken');
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',                              // Add login route
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',                           // Add register route
    name: 'register',
    component: RegisterView
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePasswordView,
    meta: { requiresAuth: true }
  },
  {
    path: '/file-upload',
    name: 'file-upload',
    component: FileUploadView,
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthed = isAuthenticated();

  if (requiresAuth && !isAuthed) {
    next({ name: 'login' });
  } else if ((to.name === 'login' || to.name === 'register') && isAuthed) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router
