<template>
<div class="container">
    <div class="file has-name is-boxed">
    <label class="file-label">
        <input class="file-input" type="file" name="file" @change="handleFileUpload">
        <span class="file-cta">
        <span class="file-icon">
            <i class="fas fa-upload"></i>
        </span>
        <span class="file-label">Choose a file…</span>
        </span>
        <span class="file-name" v-if="file">
        {{ file.name }}
        </span>
    </label>
    </div>
    <button class="button is-primary" @click="uploadFile" :disabled="!file">Upload</button>
    
    <div v-if="message" :class="{'notification': true, 'is-success': isSuccess, 'is-danger': !isSuccess}">
    {{ message }}
    </div>
</div>
    <!-- Upload Feedback Message -->
<div v-if="uploadMessage" :class="{'notification': true, 'is-success': isSuccess, 'is-danger': !isSuccess}">
{{ uploadMessage }}
</div>

<!-- Optional: Progress Bar -->
<progress class="progress is-small" :value="uploadProgress" max="100" v-if="isUploading"></progress>
</template>

<script>
import axios from 'axios';
import { API_URL } from '../constants';

export default {
data() {
    return {
    file: null,
    message: '',
    isSuccess: false,
    isUploading: false,
    uploadProgress: 0,
    };
},
methods: {
    handleFileUpload(event) {
    this.file = event.target.files[0];
    },
    async uploadFile() {
    if (!this.file) {
        this.message = 'Please select a file to upload.';
        this.isSuccess = false;
        return;
    }

    let formData = new FormData();
    formData.append('file', this.file);
    this.isUploading = true;
    this.uploadProgress = 0;

    try {
        const response = await axios.post(`${API_URL}/api/v1/upload-compatibility/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${localStorage.getItem('userToken')}`
        },
        onUploadProgress: progressEvent => {
            this.uploadProgress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
        });

        this.uploadMessage = response.data.message || 'File uploaded successfully.';
        this.isSuccess = true;
    } catch (error) {
        this.uploadMessage = error.response.data.errors.join(", ") || 'An error occurred during upload.';
        this.isSuccess = false;
    } finally {
        this.isUploading = false;
    }
    }
}
}
</script>