<template>
  <div class="container has-text-centered">
    <!-- Logo -->
    <div class="logo">
      <img src="@/assets/mixsyn-logo2.svg" alt="MixSyn Logo" style="width:320px; margin-top: 50px; margin-bottom: 20px;">
    </div>

    <div class="box" style="max-width: 400px; margin: auto;">
      <div v-if="loginError" class="notification is-danger">
        {{ loginError }}
      </div>

      <div class="field">
        <label class="label">Nom d’utilisateur</label>
        <div class="control">
          <input class="input" type="text" placeholder="E-mail" v-model="username">
        </div>
      </div>

      <div class="field">
        <label class="label">Mot de passe</label>
        <div class="control">
          <input class="input" type="password" placeholder="Mot de passe" v-model="password">
        </div>
      </div>

      <div class="field">
        <div class="control">
          <button class="button is-link" @click="login">Se connecter</button>
        </div>
      </div>

      <div class="field">
        <p>Vous n’avez pas de compte ?   <router-link to="/register">Inscrivez-vous ici</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '../constants';
import { EventBus } from '../eventBus';

export default {
  data() {
    return {
      username: '',
      password: '',
      loginError: '' // To display login errors
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(`${API_URL}/api/v1/login/`, {
          username: this.username,
          password: this.password
        });

        // Check for the first_time_login flag to redirect to password change page if needed
        if (response.data.first_time_login) {
          localStorage.setItem('userToken', response.data.token); // Set token here
          this.$router.push({ name: 'change-password' });
        } else {
          // Normal login success case
          localStorage.setItem('userToken', response.data.token);
          EventBus.emit('auth-change', true);
          this.$router.push({ name: 'home' });
          
        }
      } catch (error) {
        // Handle specific error responses from the backend
        if (error.response) {
          switch (error.response.status) {
            case 400: // HTTP_400_BAD_REQUEST
              this.loginError = error.response.data.error;
              break;
            default:
              this.loginError = "An unexpected error occurred. Please try again later.";
          }
        } else {
          // Handle other axios errors (network issues, etc.)
          this.loginError = "An error occurred during login. Please check your network connection and try again.";
        }
        console.error(error);
      }
    }
  }
}
</script>