<template>
  <div class="register">
    <Register />
  </div>
</template>

<script>
import Register from '@/components/Register.vue';


export default {
  name: 'RegisterView',
  components: {
    Register
  }
}
</script>