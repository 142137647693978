<template>
  <div class="home">
    <CompatibilityCheck />
  </div>
</template>

<script>
import CompatibilityCheck from '@/components/CompatibilityCheck.vue';


export default {
  name: 'HomeView',
  components: {
    CompatibilityCheck
  }
}
</script>